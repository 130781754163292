<template>
  <div class="package-payment-page">
    <section class="section-checkout-detail">
      <b-container class="checkout-detail-content mb-4 px-4 mb-4">
        <div class="pro-feature ck-package py-4">
          <div class="row pt-4">
            <div class="col-12 col-md-6 pm-head-notice-left">
              <p>
                <img src="../assets/img/icon/checked.png" alt="Image" />
                {{ $t('payment.your_order_has_been_submitted_successfully') }}
              </p>
              <span class="ml-0 col-12 p-0"
                >{{ $t('payment.Remaining_time_of_payment') }}：
                <span>{{ timerCount }}</span>
              </span>
            </div>
            <div class="col-12 col-md-6 pm-head-notice-right text-md-right">
              {{ $t('payment.Overdue_orders_will_be_automatically_closed') }}
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-12">
              <p class="py-2 order-number">
                {{ $t('payment.Order_number') }} ：<span class="">
                  {{ dataPayment.orderNumber }}
                </span>
              </p>
              <p class="py-2 amount-payable">
                {{ $t('payment.payTotalAmount') }} ：
                <span class="">
                  {{ formatCurrency(dataPayment.amount, 'HK$') }}
                </span>
              </p>
            </div>
            <div class="col-md-12 mt-2 mb-2">
              <hr class="new2" />
            </div>

            <div class="col-12">
              <div class="row">
                <b-col cols="12">
                  <h3 class="pm-pattern pb-3">
                    {{ $t('payment.Pattern_of_payment') }}
                  </h3>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    v-show="!loading"
                    v-slot="{ ariaDescribedby }"
                    class="m-auto"
                  >
                    <b-form-radio-group
                      id="radio-group-2"
                      v-model="selected_payment"
                      :aria-describedby="ariaDescribedby"
                      name="radio-sub-component"
                      class="w-100 justify-content-center"
                    >
                      <div
                        class="p-3 d-flex pm-form-choise justify-content-center"
                      >
                        <b-row>
                          <b-col class="pb-3">
                            <b-form-radio value="wechat">
                              <img
                                src="../assets/img/icon/wechatpay.png"
                                width="120px"
                                height="40px"
                                alt="Image"
                              />
                            </b-form-radio>
                          </b-col>

                          <b-col class="pb-3">
                            <b-form-radio value="visa"
                              ><img
                                src="../assets/img/pay_method_icon.png"
                                width="205px"
                                height="50px"
                                class="position-relative"
                                style="bottom: 5px; "
                                alt="Image"
                            /></b-form-radio>
                          </b-col>

                          <b-col class="pb-3">
                            <b-form-radio value="alipay"
                              ><img
                                src="../assets/img/icon/alipay_tm.png"
                                width="160px"
                                height="50px"
                                alt="Image"
                            /></b-form-radio>
                          </b-col>
                          <b-col class="pb-3">
                            <b-form-radio value="fps"
                              ><img
                                src="../assets/img/icon/fpsgateway.png"
                                width="50px"
                                height="50px"
                                alt="Image"
                            /></b-form-radio>
                          </b-col>
                        </b-row>
                      </div>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>
              </div>
            </div>
          </div>
          <div
            class="row mt-4 justify-content-center"
            v-if="selected_payment === 'credit_card'"
          >
            <div class="col-12 col-md-6">
              <stripe-element-card
                ref="elementRef"
                :pk="publishableKey"
                @token="tokenCreated"
                hidePostalCode
                :locale="card_locale"
                @element-change="checkErrorCard"
              />
            </div>
          </div>
          <!-- 表单部分 -->
          <div
            class="col-12 col-md-6"
            v-if="selected_payment === 'visa' && false"
          >
            <div class="cardContainer">
              <b-form-group class="userId-group noShow">
                <!-- <label for="contact_name">
                  {{ $t('payment.accountCardNumberLabel')}}
                  <span class="text-danger">* </span>
                </label> -->
                <b-input-group>
                  <b-form-input
                    :placeholder="$t('payment.accountCardNumberLabel')"
                    class="py-3 shadow-sm"
                    name="CardNo"
                    :state="validateState('CardNo')"
                    v-model="form_card.CardNo"
                    :formatter="formatBankCardNumber"
                    aria-describedby="input-cardNo-Invalid"
                  ></b-form-input>
                  <!-- <b-form-invalid-feedback id="input-cardNo-Invalid">
                        <span class="md-guide">{{
                            $t('payment.accountCardNumberErr')
                          }}</span>
                  </b-form-invalid-feedback> -->
                </b-input-group>
              </b-form-group>
              <div style="display: flex">
                <date-picker
                  format="MM/DD"
                  :placeholder="$t('payment.CardExpireDateLabel')"
                  v-model="form_card.CardExpireDate"
                  value-type="format"
                  class="cardExpireDate"
                ></date-picker>
                <!-- <b-form-group class="userId-group noShow">
                  <label for="contact_name">
                    {{ $t('payment.CardExpireDateLabel')}}
                    <span class="text-danger">* </span>
                  </label>
                    <b-form-datepicker
                      ref="ppp"
                      style="height: 40px;width:130px"
                      :placeholder="$t('payment.CardExpireDateLabel')"
                      class="shadow-sm mb-2"
                      name="CardExpireDate"
                      :state="validateState('CardExpireDate')"
                      v-model="form_card.CardExpireDate"
                      aria-describedby="input-CardExpireDate-Invalid"
                    ></b-form-datepicker>
                    <b-form-invalid-feedback id="input-CardExpireDate-Invali d">
                          <span class="md-guide">{{
                              $t('payment.CardExpireDate')
                            }}</span>
                    </b-form-invalid-feedback>
                </b-form-group> -->

                <b-form-group class="userId-group noShow">
                  <!-- <label for="contact_name">
                    {{ $t('payment.CardCVVLabel')}}
                    <span class="text-danger">* </span>
                  </label> -->
                  <b-input-group>
                    <b-form-input
                      style="height: 42px; width: 110px"
                      :placeholder="$t('payment.CardCVVLabel')"
                      type="number"
                      class="py-3 shadow-sm"
                      name="CardCVV"
                      :state="validateState('CardCVV')"
                      v-model="form_card.CardCVV"
                      aria-describedby="input-CardCVV-Invalid"
                    ></b-form-input>
                    <!-- <b-form-invalid-feedback id="input-CardCVV-Invalid">
                          <span class="md-guide">{{
                              $t('payment.CardCVVErr')
                            }}</span>
                    </b-form-invalid-feedback> -->
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
            <div class="errText">{{ $t(errText) }}</div>
          </div>
          <div class="py-5" v-if="qr_link !== null">
            <section class="text-center">
              <b-container>
                <div class="row text-center">
                  <div class="col-12 text-center d-flex justify-content-center">
                    <div class="wechat-qr">
                      <qr-code :text="qr_link" error-level="L"> </qr-code>
                      <p class="text-center">
                        {{
                          $t('payment.scanQrcode', [
                            selected_payment === 'alipay'
                              ? 'AliPay'
                              : selected_payment === 'fps'
                              ? 'FPS'
                              : 'WeChat',
                          ])
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-container>
            </section>
          </div>
          <div class="row mt-3" v-if="qr_link === null">
            <div class="col-12 text-center mt-5">
              <b-button
                type="submit"
                variant="warning"
                class="mt-4 mb-1 ck-submit"
                @click="showModal()"
                :disabled="selected_payment === null"
                v-if="!busy"
              >
                {{ $t('payment.Confirm_payment') }}</b-button
              >
              <b-button v-else variant="warning" disabled>
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </b-button>
              <p class="pm-submit-note">
                {{ $t('payment.After_successful_payment') }}
              </p>
            </div>
          </div>
          <b-modal
            ref="modal-notify_bk"
            id="modal-notify_bk"
            :hide-footer="true"
          >
            <div class="row text-center justify-content-center">
              <img
                class=""
                src="../assets/img/icon/icon-hint-red@2x.png"
                style="width: 60px"
                alt="Image"
              /><br />
              <p class="my-4 text-center w-100">
                {{ $t('booking.Are_you_sure2') }}
              </p>
            </div>
            <hr />
            <div class="row text-center">
              <div class="col-6">
                <b-button variant="secondary" @click="hideModal()">{{
                  $t('payment.Close')
                }}</b-button>
              </div>
              <div class="col-6">
                <b-button variant="warning" @click="submit">
                  {{ $t('payment.Continue_to_pay') }}
                </b-button>
              </div>
            </div>
          </b-modal>
        </div>
      </b-container>
    </section>
  </div>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import appConfig from '@/app.config';

import enums from '@/store/enums';
import config from '@/app.config';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const url = appConfig.user_service_url;

export default {
  name: 'Payment',
  components: {
    DatePicker,
    StripeElementCard,
  },
  data() {
    this.publishableKey = enums.stripe.public_token;
    // this.publishableKey = enums.stripe.public_token;
    this.url = config.user_service_url;
    return {
      timerCount: 60,
      selected_payment: null,
      token: null,
      totalAmount: null,
      qr_link: null,
      order_number: null,
      loading: false,
      errored: false,
      info_booking: null,
      lang: this.$language,
      isCardError: true,
      dataPayment: null,
      eventSource: null,
      busy: false,
      credit_card: null,

      form_card: {
        CardNo: null,
        CardExpireDate: null,
        CardCVV: null,
        CHNameFirst: null,
        CHNameLast: null,
        CHEmail: null,
        CHPhone: null,
      },
      options_CHCountry: [
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
      ],
      options_CHState: [
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
      ],
      options_CHCity: [
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
      ],
      errText: '',
      loginUserNm: '',
      loginUserId: '',
      authUsers: ['qcctester01', 'qcctester02', 'qcctester03'],
      maxAttempts: 40,
      attemptCount: 0,
      timer: null,
    };
  },
  created() {
    const data = sessionStorage.getItem('dataPayment');
    console.log(data);
    this.dataPayment = JSON.parse(data);
    this.loginUserNm = localStorage.getItem('username');
    sessionStorage.removeItem('dataPayment');
  },
  watch: {
    // 'form_card.CardNo': {
    //   handler(n, o) {
    //     if (n&&n.length==23) {
    //       this.errText=''
    //     }else{
    //       this.errText='payment.accountCardNumberErr'
    //     }
    //   },
    //   deep: true
    // },
    // 'form_card.CardExpireDate': {
    //   handler(n, o) {
    //     if (n) {
    //       this.errText=''
    //     }else{
    //       this.errText='payment.CardExpireDate'
    //     }
    //   },
    //   deep: true
    // },
    // 'form_card.CardCVV': {
    //   handler(n, o) {
    //     if (n&&n.length==4) {
    //       this.errText=''
    //     }else{
    //       this.errText='payment.CardCVVErr'
    //     }
    //   },
    //   deep: true
    // },
    card_locale() {
      if (this.selected_payment == 'credit_card') {
        this.selected_payment = null;
        setTimeout(() => (this.selected_payment = 'credit_card'), 500);
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          if (this.selected_payment !== 'visa') {
            this.$store.dispatch('paymentModule/clear');
            this.$router.push('/');
          }
        }
      },
      immediate: false,
    },
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    if (this.timer) {
      this.maxAttempts = 0;
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    formatBankCardNumber(cardNumber) {
      if (!cardNumber || cardNumber.length === 0) {
        return '';
      }
      const cleanedCardNumber = cardNumber.replace(/\D/g, '');
      const parts = [];
      for (let i = 0; i < cleanedCardNumber.length; i += 4) {
        parts.push(cleanedCardNumber.substr(i, 4));
      }
      return parts.join(' ');
    },
    formatCurrency(amount, currency) {
      const formattedNumber = amount.toLocaleString('en', {
        minimumFractionDigits: 2,
      });
      return `${currency}${formattedNumber}`;
    },
    checkErrorCard(event) {
      if (event.error || event.empty || !event.complete) {
        this.isCardError = true;
      } else {
        this.isCardError = false;
      }
    },
    preventNav(event) {
      this.$store.dispatch('paymentModule/clear');
    },
    submit() {
      this.busy = true;
      this.hideModal();
      this.loading = true;
      if (this.selected_payment == 'credit_card') {
        this.$refs.elementRef.submit();
      } else {
        this.checkOutOrder();
      }
    },
    checkOutOrder() {
      let dataSubmit = {
        amount: this.dataPayment.amount.toString(),
        orderId: this.dataPayment.orderId,
        // qfPayType: 'qfpay',
        paymentMethod: 'visa',
      };
      let dataSubmitqt = {
        amount: this.dataPayment.amount.toString(),
        orderId: this.dataPayment.orderId,
        qfPayType: '',
        paymentMethod: this.selected_payment,
      };
      if (this.selected_payment === 'fps') {
        dataSubmit.paymentMethod = 'fps';
        dataSubmitqt.qfPayType = '802001';
      }

      if (this.selected_payment === 'wechat') {
        dataSubmit.paymentMethod = 'wechat';
        dataSubmitqt.qfPayType = '800201';
      }

      if (this.selected_payment === 'visa') {
        dataSubmit.paymentMethod = 'visa';
      }
      if (this.selected_payment === 'alipay') {
        dataSubmitqt.qfPayType = '801501';
      }
      // if (this.selected_payment === 'mastercard') {
      //   dataSubmit.paymentMethod = 'mastercard';
      // }
      // visa支付
      if (this.selected_payment === 'visa') {
        this.$store
          .dispatch('paymentModule/visaPaymentDefault', dataSubmit)
          .then((data) => {
            document.write(data.pageInfo);
            document.checkoutform.submit();
          })
          .catch((data) => {
            // alert(data.returnMsg)
            this.selected_payment = '';
            this.busy = false;
            this.loading = false;
            this.$vToastify.error({
              body: data.returnMsg,
              defaultTitle: false,
            });
            // window.location.assign(window.location.origin + '/#/success/false');
          });
      } else {
        this.$store
          .dispatch('paymentModule/unifiedQfpayDefault', dataSubmitqt)
          .then((data) => {
            this.qr_link = data.qrCode;
            this.payBack(data.payUniqueSeq);
          })
          .catch((data) => {
            this.selected_payment = '';
            this.busy = false;
            this.loading = false;
            this.$vToastify.error({
              body: data.returnMsg,
              defaultTitle: false,
            });
          });
      }
    },
    payBack(payid) {
      if (this.attemptCount >= this.maxAttempts) {
        window.location.assign(
          window.location.origin + window.location.pathname + '#/success/false',
        );
        clearInterval(this.timer);
        this.maxAttempts = 0;
        return;
      } else {
        clearTimeout(this.timer);
        this.$store
          .dispatch('paymentModule/payBack', { id: payid })
          .then((data) => {
            if (data == 1) {
              window.location.assign(
                window.location.origin +
                  window.location.pathname +
                  '#/success/true',
              );
            } else if (data == null) {
              this.attemptCount++;
              console.log(this.attemptCount);
              this.timer = setTimeout(() => {
                this.payBack(payid);
              }, 5000);
            } else {
              window.location.assign(
                window.location.origin +
                  window.location.pathname +
                  '#/success/false',
              );
            }
          })
          .catch((error) => {
            console.error('PayBack failed:', error);
            this.attemptCount++;
            // this.timer = setTimeout(() => {
            //   this.payBack(payid);
            // }, 5000); 
            window.location.assign(
                window.location.origin +
                  window.location.pathname +
                  '#/success/false',
              );
          });
      }
    },
    handleQccCheckout() {
      const data = this.$store.getters['paymentModule/getPaymentData'];
      const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
      var html =
        "<form action='https://payment.viewaypay.com/cashier/payment/' method='post' name='checkoutform' style='display:none' enctype='application/x-www-form-urlencoded'>" +
        "<input type='hidden' name='Version' value=" +
        data.Version +
        '>' +
        "<input type='hidden' name='DeviceType' value=" +
        data.DeviceType +
        '>' +
        "<input type='hidden' name='CharacterSet' value=" +
        data.CharacterSet +
        '>' +
        "<input type='hidden' name='SignType' value=" +
        data.SignType +
        '>' +
        "<input type='hidden' name='IntegrationLevel' value=" +
        data.IntegrationLevel +
        '>' +
        "<input type='hidden' name='GatewayName' value=" +
        data.GatewayName +
        '>' +
        "<input type='hidden' name='OrderId' value=" +
        data.OrderId +
        '>' +
        "<input type='hidden' name='MerchantNo' value=" +
        data.MerchantNo +
        '>' +
        "<input type='hidden' name='TerminalNo' value=" +
        data.TerminalNo +
        '>' +
        "<input type='hidden' name='OrderCurr' value=" +
        data.OrderCurr +
        '>' +
        "<input type='hidden' name='OrderAmt' value=" +
        data.OrderAmt +
        '>' +
        "<input type='hidden' name='Signature' value=" +
        data.Signature +
        '>' +
        '</form>';
      document.write(html);
      document.checkoutform.submit();
    },
    showModal() {
      if (this.selected_payment === 'credit_card') {
        if (this.isCardError) {
          this.$vToastify.error({
            body: 'Error',
            defaultTitle: false,
          });
          return;
        }
      }
      this.$refs['modal-notify_bk'].show();
    },
    hideModal() {
      this.$refs['modal-notify_bk'].hide();
    },
    tokenCreated(token) {
      this.loading = true;
      var self = this;
      const requestData = {
        amount: this.dataPayment.amount,
        token: token.id,
        orderId: this.dataPayment.orderId,
      };
      this.$store
        .dispatch('paymentModule/callCreditCardPayment', requestData)
        .then(() => {
          window.location.assign(
            window.location.origin +
              window.location.pathname +
              '/#/success/true',
          );
        })
        .catch(() => {
          window.location.assign(
            window.location.origin +
              window.location.pathname +
              '/#/success/false',
          );
        })
        .finally(() => (this.loading = false));
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
  },
  computed: {
    card_locale() {
      return this.$store.getters.getLanguage;
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeRouteEnter(to, from, next) {
    if (sessionStorage.getItem('dataPayment') === null) {
      next('/');
      return;
    } else {
      next((vm) => {
        vm.timerCount = 60 * 3;
      });
      return;
    }
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem('dataPayment');
    if (this.selected_payment !== 'visa') {
      this.$store.dispatch('paymentModule/clear');
    }
    next();
  },
};
</script>
<style type="text/css" scoped>
.checkout-detail-content {
  background-color: #fff;
}
.pm-head-notice-left p {
  color: #ffb423;
  font-size: 1rem;
  padding-bottom: 0;
  margin-bottom: 10px;
}
.pm-head-notice-left img {
  width: 20px;
}
.pm-head-notice-left span,
.order-number,
.amount-payable {
  color: #333333;
}
.pm-head-notice-left span > span {
  color: #f66c4b;
}
.pm-head-notice-right {
  color: #1997f8;
  font-size: 0.9rem;
}
.order-number span {
  color: #1997f8;
  /*font-size: 1rem;*/
}
.amount-payable span {
  color: #f66c4b;
  font-size: 1.1rem;
}

hr.new2 {
  border-top: 1px dashed #cccccc;
}
.pm-pattern-choise img {
  width: 35px;
}
.pm-addbank label {
  color: #1997f8;
}
.pm-form-choise {
  border: 1px solid #cccccc54;
}
.pm-submit-note {
  color: #666666e0;
  margin-top: 0;
  font-size: 13px;
}
.form-group.col-md-2.ck-iput-2 {
  flex: 0 0 35%;
  max-width: 35%;
}
.form-group.col-md-6.ck-iput-7 {
  flex: 0 0 52.5%;
  max-width: 52.5%;
}
.checkout-title h4 > span {
  font-size: 13px;
  border-left: 4px solid #006bba;
  padding-right: 15px;
  line-height: 2;
}
.ck-package h4 > span {
  color: #2296df;
}
.ck-date p {
  color: #333333;
}
.ck-package h4 {
  font-size: 1.2rem;
  color: #333333;
  font-weight: 400;
}
#ck_ticket_number {
  line-height: 2;
  height: 1.6rem;
  width: 4rem;
  border-color: rgb(122 123 151 / 50%);
  text-align: center;
}
.ck-up-number,
.ck-down-number {
  width: 1.8rem;
  background-color: #f6a604;
  color: #fff;
  border: 1px solid;
}
.ck-row {
  color: #2296df;
  font-size: 1.2rem;
}
.ck-row a {
  color: #2296df;
}
.ck-details label {
  color: #333333;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1.5px;
}
.ck-details input,
.ck-details select {
  border-radius: unset;
  height: calc(1.1em + 1.06rem + 2px);
  width: 80%;
}
.ck-details textarea {
  border-radius: unset;
}
.ck-details .form-group.col-md-5.ck-iput-2 input {
  width: 90%;
}
.ck-details .form-group.col-md-3.ck-iput-3 input {
  width: 100%;
}
.ck-details label > span {
  color: red;
  font-weight: 500;
  font-size: 1.1rem;
}
.ck-details .ck-iput-4 select {
  float: right;
}
.ck-details .ck-iput-4 label {
  margin-left: 20px;
}
table.ck-table {
  background-color: #fff;
}
table.ck-table thead th {
  background-color: #fff;
}
.ck-details .table-price label > span {
  color: #f66c4b;
  font-weight: 400;
  font-size: 1rem;
}
.c-red {
  color: #f66c4b;
}
.ck-iput-t3 input,
.ck-iput-t3 label,
.ck-iput-t3 span.ck-error {
  margin-left: 4.2rem;
}
.ck-iput-t2 input,
.ck-iput-t2 label,
.ck-iput-t2 span.ck-error {
  margin-left: 2rem;
}
.ck-error {
  color: #f66c4b;
  /*letter-spacing: 1.5px;*/
  /*line-height: 3;*/
}
.ck-s3-radio-text label {
  color: #2296df;
}
.ck-s3-radio-text label > span {
  color: #f6a604;
  font-weight: unset;
}
.ck-payment-method .custom-radio label {
  color: #2296df;
  padding: 0.1rem 5rem;
  border: 1px solid #2296df;
}
.ck-payment-method span {
  margin-left: 21rem;
}
button.ck-submit {
  background-color: #f6a604;
  padding: 0.5rem 1.2rem;
  border-radius: 4px !important;
  border: 1px solid #f6a604;
}
.ck-date .datepicker-popup {
  display: none;
}
.ck-details .departures-price {
  width: 100%;
}
#modal-notify_bk .modal-content {
  border-radius: 0;
  border: unset;
}
.loading {
  display: none;
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  /* left, top and position just for the demo! */
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99999999;
}

@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise.
       use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .icon--payment {
    height: 50px;
    width: auto !important;
  }
}

.package-payment-page {
  background-color: #e0eaeb47;
}
header.header-global {
  background: #fff;
}
.wechat-qr {
  height: 256px;
  border: 1px solid #11111114;
}
.cardContainer {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
    0 0.126rem 0.225rem rgba(122, 123, 151, 0.3) !important;
  height: 42px;
}
.noShow {
  input {
    box-shadow: none !important;
    border: none;
  }
  ::v-deep .h-auto,
  .btn {
    background-image: none !important; /* 移除背景图像 */
  }
  ::v-deep .b-icon.bi {
    display: none;
  }
  .shadow-sm {
    box-shadow: none !important;
    border: none;
  }
  /* ::v-deep .show > .btn-secondary.dropdown-toggle{
    opacity: 0;
  } */
  /* ::v-deep .btn-group{
      width: 100%;
      height: 100%;
  }
  ::v-deep .input-group-append {
      width: 100%;
      height: 100%;
      position: absolute;
  }
  ::v-deep .input-group > .input-group-append > .btn-group > .btn{
    opacity: 0;
  } */
}
.cardExpireDate {
  width: 120px;
}
::v-deep .cardExpireDate input {
  height: 42px;
  border: none;
  text-align: right;
  box-shadow: none;
}
::v-deep .cardExpireDate input::placeholder {
  color: #ccc; /* 将颜色更改为红色 */
}
::v-deep .cardExpireDate .mx-icon-calendar {
  visibility: hidden;
}
.errText {
  font-size: 14px;
  color: red;
}
</style>
